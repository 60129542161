import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {RouterModule} from "@angular/router";
import {NgModule} from "@angular/core";
import {NzDropDownModule} from "ng-zorro-antd/dropdown";
import {NzModalModule} from "ng-zorro-antd/modal";
import {NzSelectModule} from "ng-zorro-antd/select";
import {NzInputModule} from "ng-zorro-antd/input";
import {NzGridModule} from "ng-zorro-antd/grid";
import {NzLayoutModule} from "ng-zorro-antd/layout";
import {NzButtonModule} from "ng-zorro-antd/button";
import {NzIconModule} from "ng-zorro-antd/icon";
import {NzRadioModule} from "ng-zorro-antd/radio";
import {HttpClientModule} from "@angular/common/http";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {NzImageModule} from "ng-zorro-antd/image";
import {OpenLayoutComponent} from "./layout/open/open-layout.component";
import {NzDividerModule} from "ng-zorro-antd/divider";
import {NzProgressModule} from "ng-zorro-antd/progress";
import {NzAvatarModule} from "ng-zorro-antd/avatar";
import {NzCardModule} from "ng-zorro-antd/card";
@NgModule({
  declarations:[
  ],
  imports: [
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NzDropDownModule,
    NzRadioModule,
    NzIconModule,
    NzButtonModule,
    NzLayoutModule,
    NzGridModule,
    NzInputModule,
    NzSelectModule,
    NzModalModule,
    HttpClientModule,
    NzImageModule,
    NzDividerModule,
    NzProgressModule,
    NzAvatarModule,
    NzCardModule
  ],
  exports: [
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NzDropDownModule,
    NzIconModule,
    NzButtonModule,
    NzLayoutModule,
    NzGridModule,
    NzInputModule,
    NzSelectModule,
    NzModalModule,
    HttpClientModule,
    NzImageModule,
    NzDividerModule,
    NzProgressModule,
    NzAvatarModule,
    NzCardModule
  ]
})
export class SharedModule {}
