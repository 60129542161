import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { en_US } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { FormsModule } from '@angular/forms';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {OpenLayoutComponent} from "./layout/open/open-layout.component";
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {SharedModule} from "./shared.module";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {IconsProviderModule} from "./icons-provider.module";
import {NzLayoutModule} from "ng-zorro-antd/layout";
import {NzMenuModule} from "ng-zorro-antd/menu";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {NzAvatarModule} from "ng-zorro-antd/avatar";
import {NzDropDownModule} from "ng-zorro-antd/dropdown";
import {NzBreadCrumbModule} from "ng-zorro-antd/breadcrumb";
import {NzGridModule} from "ng-zorro-antd/grid";
import {NzModalModule} from "ng-zorro-antd/modal";
import {RouterModule} from "@angular/router";
import {AppRoutingModule} from "./app-routing.module";
import {BrowserModule} from "@angular/platform-browser";
import {NzDividerModule} from "ng-zorro-antd/divider";
import {NzProgressModule} from "ng-zorro-antd/progress";
import {NzCardModule} from "ng-zorro-antd/card";
import {NzDrawerModule} from "ng-zorro-antd/drawer";
import {NzSwitchModule} from "ng-zorro-antd/switch";
import {NzListModule} from "ng-zorro-antd/list";
registerLocaleData(en);

export function I18nHttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, `assets/i18n/`, '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    OpenLayoutComponent
  ],
  imports: [
    AppRoutingModule,
    IconsProviderModule,
    NzLayoutModule,
    NzMenuModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NzAvatarModule,
    NzDropDownModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en-US',
      loader: {
        provide: TranslateLoader,
        useFactory: (I18nHttpLoaderFactory),
        deps: [HttpClient]
      }
    }),
    NzBreadCrumbModule,
    NzGridModule,
    NzModalModule,
    SharedModule,
    NzDividerModule,
    NzProgressModule,
    NzCardModule,
    NzDrawerModule,
    NzSwitchModule,
    NzListModule,
  ],
  providers: [{ provide: NZ_I18N, useValue: en_US }],
  bootstrap: [AppComponent]
})
export class AppModule { }
