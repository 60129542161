<nz-layout class="track-app-header">
  <nz-header class="mobile-header">
    <div>
      <img style="width: 70px;" class="track-logo" src="/assets/img/ankunftszeit.png" />
      <span style="display: inline-block;color:#004f9e;font-weight: bold;margin-left: 20px;">{{ 'customTrans.nexxTrackPoral' | translate }}</span>
      <button nz-button nzType="default" class="menubutton"  (click)="sideMenuOpen();">
        <i nz-icon [nzType]="sideMenuVisible ? 'menu-unfold' : 'menu-fold'"></i>
      </button>
    </div>
  </nz-header>
  <nz-drawer class="sidemenu"  [nzClosable]="false"  [nzVisible]="sideMenuVisible" [nzPlacement]="'left'" (nzOnClose)="sideMenuClose();">
    <!--  [nzMask]="'false'" [nzMaskClosable]="'false'"  -->
    <ng-container *nzDrawerContent>
      <div style="padding: 10px 12px;text-align: center;border-bottom: 1px solid #f0f0f0;margin-bottom: 0px;background: #ffffff;">
        <img style="width: 100px;" class="track-logo" src="/assets/img/ankunftszeit.png" />
        <span style="display: block;font-size: 17px;font-weight: 600; color: #004f9e;margin-top: 10px;">{{ 'customTrans.nexxTrack' | translate }}</span>
      </div>
      <ul nz-menu nzMode="inline">
<!--        <li nz-menu-item nz-tooltip nzTooltipPlacement="right" (click)="sideMenuClose();" [routerLink]="'/home'" nzSelected>-->
<!--          <i class="fa fa-home menuicon"></i>-->
<!--          <span>{{ 'customTrans.home' | translate }}</span>-->
<!--        </li>-->
<!--        <li nz-menu-item nz-tooltip nzTooltipPlacement="right" (click)="sideMenuClose();" >-->
<!--          <i class="fa fa-pencil-square-o menuicon"></i>-->
<!--          <span>{{ 'customTrans.registerComplaint' | translate }}</span>-->
<!--        </li>-->
<!--        <li nz-menu-item nz-tooltip nzTooltipPlacement="right" (click)="sideMenuClose();" >-->
<!--          <i class="fa fa-envelope menuicon"></i>-->
<!--          <span>{{ 'customTrans.reachUs' | translate }}</span>-->
<!--        </li>-->
        <li nz-menu-item nz-tooltip nzTooltipPlacement="right">
          <i class="fa fa-globe menuicon"></i>
          <span>{{ 'customTrans.language' | translate }}</span>
          <div style="float: right;">
            <button style="padding:0px !important" nz-button nz-dropdown [nzDropdownMenu]="menu4">
              <img *ngIf="this.selectedLanguage=='de'" src="./assets/flag/german.png" style="width: 22px;margin-left: 10px;margin-right: 10px;"/><span *ngIf="this.selectedLanguage=='de'" >DE</span>
              <img *ngIf="this.selectedLanguage=='en'" src="./assets/flag/uk.png" style="width: 22px;margin-left: 10px;margin-right: 10px;"/><span *ngIf="this.selectedLanguage=='en'">EN</span>
              <i nz-icon nzType="down"></i>
            </button>
            <nz-dropdown-menu #menu4="nzDropdownMenu">
              <ul nz-menu>
                <li nz-menu-item (click)="switchLanguage('de')"><img  src="./assets/flag/german.png" style="border: 1px solid #ffffff;width:22px;margin-right: 10px;"/>Deutsche</li>
                <li nz-menu-item (click)="switchLanguage('en')"><img  src="./assets/flag/uk.png" style="border: 1px solid #ffffff;width:22px;margin-right: 10px;"/>English</li>
              </ul>
            </nz-dropdown-menu>
          </div>
        </li>
      </ul>
    </ng-container>
  </nz-drawer>
  <nz-header class="desktop-header">
    <ul class="menu-ul" nz-menu nzMode="horizontal" nzSelectable="false">
      <div nz-row nzJustify="center" class="ant-row-center">
        <div nz-col nzSpan="4" style="text-align: center;">
<!--          <li nz-menu-item nzSelected (click)="openPTC();">-->
<!--            <i class="fa fa-home menuicon"></i>-->
<!--            {{ 'customTrans.home' | translate }}-->
<!--          </li>-->
        </div>
        <div nz-col nzSpan="4" style="text-align: center;">
<!--          <li nz-menu-item>-->
<!--            <i class="fa fa-pencil-square-o menuicon"></i>-->
<!--            {{ 'customTrans.registerComplaint' | translate }}-->
<!--          </li>-->
        </div>
        <div nz-col nzSpan="4" style="text-align: center;">
          <div class="center-logo-container" routerLink="home" style="cursor: pointer;">
            <img class="track-logo" src="/assets/img/ankunftszeit.png" />
          </div>
        </div>
        <div nz-col nzSpan="4" style="text-align: center;">
<!--          <li nz-menu-item>-->
<!--            <i class="fa fa-envelope menuicon"></i>-->
<!--            {{ 'customTrans.reachUs' | translate }}-->
<!--          </li>-->
        </div>
        <div nz-col nzSpan="4" style="text-align: center;">
          <li nz-menu-item>
                <a class="language-menu" nz-dropdown [nzDropdownMenu]="menu" nzPlacement="bottomRight">
                  <i class="fa fa-globe menuicon"></i>
                  {{ 'customTrans.language' | translate }}
                  <img *ngIf="this.selectedLanguage=='de'" src="./assets/flag/german-round.png" style="width: 22px;margin-left: 10px;margin-right: 10px;"/><span *ngIf="this.selectedLanguage=='de'" >DE</span>
                  <img *ngIf="this.selectedLanguage=='en'" src="./assets/flag/uk-round.png" style="width: 22px;margin-left: 10px;margin-right: 10px;"/><span *ngIf="this.selectedLanguage=='en'">EN</span>
                </a>
                <nz-dropdown-menu #menu="nzDropdownMenu">
                  <ul nz-menu nzSelectable>
                    <li nz-menu-item (click)="switchLanguage('de')"><img   src="./assets/flag/german-round.png" style="border: 1px solid #ffffff;width:22px;margin-right: 10px;"/>Deutsch</li>
                    <li nz-menu-item (click)="switchLanguage('en')"><img   src="./assets/flag/uk-round.png" style="border: 1px solid #ffffff;width:22px;margin-right: 10px;"/>English</li>
                  </ul>
                </nz-dropdown-menu>
          </li>
        </div>
      </div>
    </ul>
  </nz-header>
  <router-outlet></router-outlet>
  <div nz-row nzGutter="16" nzJustify="center" class="feature-section" id="feature-section" *ngIf="current_route!='/daten' && current_route!='/imprint'">
    <div class="feature-card" nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="8" nzXl="6">
      <div nz-row>
        <div nz-col nzSpan="5">
          <img class="feature-icon" src="/assets/svg/delivery-boy-icon.svg" style="background: #fff;"/>
        </div>
        <div nz-col nzSpan="16" class="feature-text-content">
<!--          <h3 class="feature-text-content-bold">{{ 'customTrans.OnTimeDeliveryAsPromised' | translate }}</h3>-->
          <p>{{ 'customTrans.youCanExpectToReceiveYourOrderByThisDate' | translate }}</p>
        </div>
      </div>
    </div>
    <div class="feature-card" nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="8" nzXl="6">
      <div nz-row>
        <div nz-col nzSpan="5" style="background: #fcfcfd;border-radius: 50%; width: 60px;height: 60px;display: inline-block; padding-top: 11px; max-width: 60px;    box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);">
          <img class="feature-icon" src="/assets/img/hand_img_circle.png" style="width: 30px;margin: 0px auto;display: block; box-shadow: none;z-index: 999;background: transparent;border-radius: 0px;"/>
        </div>
        <div nz-col nzSpan="16" class="feature-text-content">
<!--          <h3 class="feature-text-content-bold">{{ 'customTrans.fastAndSecure' | translate }}</h3>-->
          <p>{{ 'customTrans.yourOrderHasBeenReceivedAndCourierTrackingHasBeenEnabled' | translate }}</p>
        </div>
      </div>
    </div>
    <div class="feature-card" nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="8" nzXl="6">
      <div nz-row>
        <div nz-col nzSpan="5" style="background: #fcfcfd;border-radius: 50%; width: 60px;height: 60px;display: inline-block; padding-top: 11px; max-width: 60px;box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);">
          <img class="feature-icon" src="/assets/img/clock_img.png" style="width: 40px;margin: 0px auto;display: block; box-shadow: none;z-index: 999;background: transparent;border-radius: 0px;"/>
        </div>
        <div nz-col nzSpan="16" class="feature-text-content">
<!--          <h3 class="feature-text-content-bold">{{ 'customTrans.247LiveSupport' | translate }}</h3>-->
          <p>{{ 'customTrans.featureThree' | translate }}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="counter-bg" style="display: none;">
    <div class="counter-section trolly-bg">
      <div nz-row nzJustify="center">
        <div class="block-title-icon">
          <img src="/assets/svg/shield.svg" style="width: 60px;"/>
          <span style="font-size: 22px;font-weight: 500; margin-left: 12px;">
            {{ 'customTrans.oneSolutionManyBenefits' | translate }}
            <nz-divider class="header-divider"></nz-divider>
          </span>
        </div>
      </div>
      <div nz-row nzJustify="center">
        <p style="max-width: 600px;text-align: center;padding: 0px 30px;">
          {{ 'customTrans.itEasierThanEverToShipOnlineGetQuotesSchedulePickupsFindLocationsTrackShipmentsAndMore' | translate }}
        </p>
      </div>

      <div nz-row nzJustify="center" class="counter-block">
        <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="4" nzXl="4">
          <div class="counter-body">
            <img src="/assets/svg/customer.svg" class="counter-icon"/>
            <h2 class="counter-number">999+</h2>
            <h4 class="counter-text">{{ 'customTrans.happyCustomers' | translate }}</h4>
          </div>
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="4" nzXl="4">
          <div class="counter-body">
            <img src="/assets/svg/like.svg" class="counter-icon"/>
            <h2 class="counter-number">750+</h2>
            <h4 class="counter-text">{{ 'customTrans.feedbacksAndReviews' | translate }}</h4>
          </div>
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="4" nzXl="4">
          <div class="counter-body">
            <img src="/assets/svg/navigation.svg" class="counter-icon"/>
            <h2 class="counter-number">500+</h2>
            <h4 class="counter-text">{{ 'customTrans.ruralDelivery' | translate }}</h4>
          </div>
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="4" nzXl="4">
          <div class="counter-body">
            <img src="/assets/svg/approval.svg" class="counter-icon"/>
            <h2 class="counter-number">16500+</h2>
            <h4 class="counter-text">{{ 'customTrans.successfulDelivery' | translate }}</h4>
          </div>
        </div>
      </div>
    </div>
  </div>
  <nz-footer>
    <div nz-row nzJustify="center">
      <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="5" nzXl="5" class="footer-1 gutter-row">
        <div>
          <img style="width: 60px;" src="/assets/img/ankunftszeit.png" alt="logo" />
          <p style="display: inline-block;margin-left: 20px;margin-bottom: 0px;font-size: 1.5em;font-weight: 500;">{{ 'customTrans.nexxTrackPoral' | translate }}</p>
        </div>
<!--        <p class="txt-justify top-space" style="margin-top: 20px;color: #000000;">-->
<!--          {{ 'customTrans.nexxTrackPoralDesc' | translate }}-->
<!--        </p>-->
        <ul class="storeicon" style="margin-top: 40px;">
          <li>
            <a><img style="width: 120px;" src="/assets/img/playstore.png" alt="logo" /></a>
          </li>
          <li>
            <a><img style="width: 130px;" src="/assets/img/appstore.png" alt="logo" /></a>
          </li>
        </ul>
      </div>
      <div class="gutter-row"  nz-col nzXs="24" nzSm="24" nzMd="0" nzLg="3" nzXl="3">

      </div>
      <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="5" nzXl="5" class="footer-2 txt-center gutter-row">
        <ul>
          <h2 class="bottom-space">{{ 'customTrans.nexxTrackPoral' | translate }}</h2>
          <li><a href="https://www.ptc-telematik.de/" target="_blank" style="color:#000;cursor: pointer;">PTC Telematik GmbH</a></li>


          <li><a routerLink="daten" style="color:#000;">{{ 'customTrans.dataProduction' | translate }}</a></li>
          <li><a routerLink="imprint" style="color:#000;">{{ 'customTrans.imprint' | translate }}</a></li>

        </ul>
      </div>
      <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="6" nzXl="6" class="footer-4 gutter-row">
        <h2 class="txt-center bottom-space">{{ 'customTrans.contactUs' | translate }}</h2>
        <ul>
          <li>
<!--            <img nz-image style="vertical-align:top;" nzSrc="/assets/svg/address.svg" alt="" />&nbsp;&nbsp;-->
            <p class="d-inline" style="display: inline-block;">Rheinstraße 2a,  <br>
              56068 Koblenz, <br>
              Deutschland</p>
          </li>
          <li>
<!--            <img nz-image nzSrc="/assets/svg/phone.svg" alt="" />-->
            +492619735230</li>
          <li>
<!--            <img nz-image nzSrc="/assets/svg/email.svg" alt="" />-->
            anfrage@ptc-ankunftszeit.de</li>
        </ul>
      </div>
    </div>
    <div nz-row nzJustify="center">
<!--      <p><b>{{ 'customTrans.copyright2021ByInfiwareSoftwareSolutionsPvtLtd' | translate }}</b></p>-->
    </div>
  </nz-footer>
  <div class="footer-block">
    <div class="bottom_animation">
      <div class="road"></div>
      <div class="city"></div>
      <div class="car">
        <img src="/assets/svg/car.svg" />
      </div>
      <div class="truck">
        <img src="/assets/svg/truck.svg" />
      </div>
<!--      <div class="scooter1">-->
<!--        <img src="/assets/svg/scooter1.svg" />-->
<!--      </div>-->
      <div class="scooter2">
        <img src="/assets/svg/scooter2.svg" />
      </div>
    </div>
  </div>
</nz-layout>
