import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {OpenLayoutComponent} from "./layout/open/open-layout.component";
import {AlertService} from "./services/alert.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {DatetimeService} from "./services/datetime.service";
import {DatePipe} from "@angular/common";

const routes: Routes = [
  {
    path: '',
    component: OpenLayoutComponent,
    children: [
      { path: '', pathMatch: 'full', redirectTo: '/home' },
      { path: 'daten', loadChildren: () => import('./pages/datenschutz/datenschutz.module').then(m => m.DatenschutzModule) },
      { path: 'imprint', loadChildren: () => import('./pages/impressum/impressum.module').then(m => m.ImpressumModule) },
      { path: 'home', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule) },
      { path: 'search-result/:trackingID', loadChildren: () => import('./pages/search-result/search-result.module').then(m => m.SearchResultModule) },
    ]
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes),TranslateModule],
  exports: [RouterModule,TranslateModule],
  providers: [DatePipe,AlertService,NzNotificationService,DatetimeService],
})
export class AppRoutingModule { }
