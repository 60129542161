import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {NzNotificationService} from "ng-zorro-antd/notification";

@Injectable()
export class AlertService {

  constructor(private notification: NzNotificationService) {
  }

  handleError(err) {
    this.printErrorMessage(err);
    if (err.status == 422) {
      if (err.error.errors) {
        let validationError = err.error.errors;
        let validationErrorMessage = '';
        for (let ve of validationError) {
          validationErrorMessage = validationErrorMessage + ' ' + ve.msg + '\n';
        }
        this.notification.create(
          'error',
          'Error Notification',
          err.error.message + ' ' + validationErrorMessage
        );
      } else {
        this.notification.create(
          'error',
          'Error Notification',
          err.error.message
        );
      }
    } else {

      if (Boolean(err.error.exception.response.message) && err.error.exception.response.message == 'License does not exist!') {
        this.notification.create(
          'info',
          'Notification',
          'License not yet added',
        );
      } else {
        this.notification.create(
          'error',
          'Error Notification',
          err.error.statusText,
        );
      }
    }
  }

  printErrorMessage(err) {
    console.error(JSON.stringify(err));
  }
}
