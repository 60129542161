import { Injectable } from '@angular/core';
import {DatePipe, formatDate} from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class DatetimeService {

  constructor() { }

  localDateString(dateString, language = "en-US") {
    let langLocale="en-US";
    if(language=="de_DE"){
      let langLocale="de-DE";
    }
    let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    let localDateString = dateString.toLocaleDateString(langLocale, options);
    console.log("localDateString", localDateString);
    return localDateString;
  }

  getDayFromDate(dateString, language = "en-US") {
    let langLocale="en-US";
    if(language=="de_DE"){
      let langLocale="de-DE";
    }
    let day = new Date(dateString);
    let d = day.getDate();
    let m = day.toLocaleDateString(langLocale, { month: "short" });
    let y = day.getFullYear();
    let w = day.toLocaleDateString(langLocale, { weekday: 'long' });
    let localDateString = day.toLocaleDateString(langLocale, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
    let shortDateString = day.toLocaleDateString(langLocale, { year: 'numeric', month: 'short', day: 'numeric' })
    let date = d + "-" + m + "-" + y;

    return {
      date: d,
      month: m,
      year: y,
      weekday: w,
      dateString: localDateString,
      shortDate: shortDateString,
      formatedDate: date
    };
  }

  getFormatedDate(dateTimeString, language = "en-US") {
    let langLocale="en-US";
    if(language=="de_DE"){
      let langLocale="de-DE";
    }
    let formatedDate=formatDate(dateTimeString, 'dd.MM.yyyy', langLocale);
    if(language=="en-US"){
      formatedDate=formatDate(dateTimeString, 'dd-MM-yyyy', langLocale);
    }
    return formatedDate;
  }
  getFormattedTime(dateTimeString, language = "en-US"){
    let langLocale="en-US";
    if(language=="de_DE"){
      let langLocale="de-DE";
    }
    var offset = new Date().getTimezoneOffset();
    console.log(offset);

    let dateTime=new Date(dateTimeString);
    // if(offset<0){
    //   offset=offset*-1;
    // }
    var offsetDateTime = new Date(dateTime.getTime() + (offset * 60 * 1000));

    let formatedTime=formatDate(dateTimeString, 'hh:mm', langLocale);
    if(language=="en-US"){
      formatedTime=formatDate(dateTimeString, 'hh:mm', langLocale);
    }
    let datmsg="offset :"+offset+" "+" ISO String :"+dateTimeString+" ISO Date : "+dateTime+" Coverted :"+offsetDateTime +" formatedTime :"+formatedTime;
    // alert(datmsg);
    console.log(datmsg)
    let hours:any=dateTime.getHours();
    let minutes:any=dateTime.getMinutes();
    if(hours<10){
      hours="0"+hours;
    }
    if(minutes<10){
      minutes="0"+minutes;
    }
    return hours+":"+minutes;
  }
}
