import {Component, Input} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {InputBoolean} from "ng-zorro-antd/core/util";
import {NavigationEnd, Router} from "@angular/router";
import {filter} from "rxjs/operators";

@Component({
  selector: 'app-open-layout',
  templateUrl: './open-layout.component.html',
  styleUrls: ['./open-layout.component.css']
})
export class OpenLayoutComponent {
  isCollapsed = true;
  sideMenuVisible = false;
  @Input() @InputBoolean() showLangText = true;
  selectedLanguage = 'de';
  curLangCode = 'de';
  langs: any = [{abbr : 'en' , text : 'English' , code : 'en_US'},
    {abbr : 'de' , text : 'Deutsch' , code : 'de_DE'}];
  isLoginModelVisible = false;
  isRegisterModelVisible = false;
  loginPasswordVisible=false;
  registerPasswordVisible=false;

  current_route='';

  constructor(private translate: TranslateService,private router: Router) {

    let lang=localStorage.getItem("nexxTrackSelectedLanguage");
    if(Boolean(lang) && (lang!="" && lang=="en")){
      translate.setDefaultLang('en-US');
      translate.use('en-US');
      this.selectedLanguage='en';
    }else if(Boolean(lang) && (lang!="" && lang=="de")){
      translate.setDefaultLang('de_DE');
      translate.use('de_DE');
      this.selectedLanguage='de';
    }else{
      translate.setDefaultLang('en-US');
      translate.use('en-US');
      localStorage.setItem("nexxTrackSelectedLanguage", "en");
      this.selectedLanguage='en';
    }
    let that=this;
    setTimeout(function(){
      console.log(" current :"+that.translate.currentLang)
    },1000)
    this.current_route=this.router.url;
    console.log("this.current_route:"+this.current_route);
  }

  ngOnInit() {

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      this.current_route=this.router.url;
      console.log("this.current_route:"+this.current_route);
    });
  }

  switchLanguage(code) {
    if(code=='en'){
      this.selectedLanguage='en';
      this.translate.use('en-US');
      localStorage.setItem("nexxTrackSelectedLanguage", "en");
    }else{
      this.selectedLanguage='de';
      this.translate.use('de_DE');
      localStorage.setItem("nexxTrackSelectedLanguage", "de");
    }
    window.location.reload();
  }
  showLoginModel(): void {
    this.isLoginModelVisible = true;
  }
  handleCancelLoginModel(): void {
    this.isLoginModelVisible = false;
  }
  showRegisterModel(): void {
    this.isRegisterModelVisible = true;
  }
  handleCancelRegisterModel(): void {
    this.isRegisterModelVisible = false;
  }
  sideMenuOpen(): void {
    this.sideMenuVisible = true;
  }

  sideMenuClose(): void {
    this.sideMenuVisible = false;
  }
  openPTC() : void {
    window.open("http://www.ptc-telematik.de/", "_blank");
  }
  onEvent(event) {
    event.stopPropagation();
  }
}
