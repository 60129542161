import {Component, Input} from '@angular/core';
import {InputBoolean} from "ng-zorro-antd/core/util";
import {TranslateService} from "@ngx-translate/core";
import {NavigationEnd, Router} from "@angular/router";
import { filter } from 'rxjs/operators';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'dev';
  isCollapsed = false;
  @Input() @InputBoolean() showLangText = true;
  selectedLanguage = 'en';
  curLangCode = 'en';
  langs: any = [{abbr : 'en' , text : 'English' , code : 'en_US'},
    {abbr : 'de' , text : 'Sprache' , code : 'de_DE'}];
  constructor(private translate: TranslateService,private router: Router,) {
    translate.setDefaultLang('en-US');
    translate.use('en-US');
  }
  ngOnInit() {
    this.router.events.pipe(filter(evt => evt instanceof NavigationEnd)).subscribe(() => {

    });
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });
  }
  switchLanguage(code) {
    if(code=='en_US'){
      this.selectedLanguage='en';
      this.translate.use('en-US');
    }else{
      this.selectedLanguage='de';
      this.translate.use('de_DE');
    }
  }
}
